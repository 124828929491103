export const GET_ALL = 'GET_ALL'
export const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const SET_USER = 'SET_ALL'
export const USERS_ERROR = 'USERS_ERROR'
export const SIGN_OUT = 'SIGN_OUT'
export const MONTH_CHANGE = 'MONTH_CHANGE'
export const SIDE_BAR_SHOW = 'SIDE_BAR_SHOW'
export const COOKIES_ID = 'TV_TOKEN'
export const EMPLOYEE_CHANGE = 'EMPLOYEE_CHANGE'
export const GLOBAL = 'Global'
export const INTERNAL = 'Internal'
export const RETAINER = 'Retainer'
export const PROJECT = 'Project'
export const BILLABLEDAYS = 22
//export const API_BASE = 'http://localhost:3000/api'
export const API_BASE = 'https://true-view.slz.io/api'

export const MONTH_SHORT_ARR = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const getEmotion = (tarPer) => {
  let res = {
    emot: '',
    msg: '',
  }
  if (tarPer < 30) {
    res.emot = '🤨'
    res.msg = "Your plate's looking a little empty there"
  } else if (tarPer < 60) {
    res.emot = '🙂'
    res.msg = 'You could do with a little more'
  } else if (tarPer < 90) {
    res.emot = '😌'
    res.msg = "Not too much, not too little. It's just right."
  } else if (tarPer < 120) {
    res.emot = '😍'
    res.msg = "You're doing great!"
  } else if (tarPer < 1000) {
    res.emot = '🤩'
    res.msg = "You're on fire! But be careful not to burn out."
  }

  return res
}

export const convertMonthToObj = (monthStr) => {
  const arr = monthStr.split('/')
  const isMonthEqual = (element) => element === arr[0]
  const obj = {
    month: MONTH_SHORT_ARR.findIndex(isMonthEqual),
    year: parseInt(arr[1]),
  }
  return obj
}

export const departmentViewTableHeader = [
  {
    key: 'employee',
    label: 'Employee',
    _props: { scope: 'col' },
  },
  {
    key: 'retainer',
    label: 'Retainer',
    _props: { scope: 'col' },
  },
  {
    key: 'projects',
    label: 'Projects',
    _props: { scope: 'col' },
  },
  {
    key: 'internal',
    label: 'Internal',
    _props: { scope: 'col' },
  },
  {
    key: 'global',
    label: 'Global',
    _props: { scope: 'col' },
  },
  {
    key: 'Utilization',
    label: 'Utilization',
    _props: { scope: 'col' },
  },
]

export const ProjectViewTableHeader = [
  {
    key: 'employee',
    label: 'Employee',
    _props: { scope: 'col' },
  },
  {
    key: 'billed',
    label: 'Billed',
    _props: { scope: 'col' },
  },
]
